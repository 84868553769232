import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

export const getRefreshToken = createAsyncThunk(
  "getRefreshToken",
  async (_, { rejectWithValue }) => {
    const params = {};
    const token = localStorage.getItem("refreshToken");
    params.refresh = token;

    try {
      const { data } = await axiosInstance.post("/token/refresh/", params);
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postLogout = createAsyncThunk(
  "postLogout",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/token/`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const passwordReset = createAsyncThunk(
  "passwordReset",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/password_reset/`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const passwordСhanging = createAsyncThunk(
  "passwordСhanging",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/password_reset/confirm/`,
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  isAuthenticated: false,
  success: {
    success: false,
    reset: false,
    changing: false,
  },
  isCorrectAdminEmail: false,
  successSetEmail: false,
  errorsSetEmail: null,
  errors: null,
  errorsPas: {
    password: null,
  },
  loaders: {
    common: false,
    password: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.success.success = false;
      state.errors = null;
      localStorage.removeItem("refreshInterval");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
    clearSuccessReset: (state) => {
      state.success.reset = false;
    },
    clearSuccessChanging: (state) => {
      state.success.changing = false;
    },
    clearError: (state) => {
      state.errors = false;
    },
    clearErrorPassword: (state) => {
      state.errorsPas.password = false;
    },
  },

  extraReducers: (builder) => {
    // getRefreshToken
    builder.addCase(getRefreshToken.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getRefreshToken.fulfilled, (state, { payload }) => {
      const { access } = payload;
      state.loaders.common = false;
      localStorage.setItem("accessToken", access);
    });
    builder.addCase(getRefreshToken.rejected, (state, action) => {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      state.errors = action?.payload?.data;
      state.loaders.common = false;
    });

    //postLogout
    builder.addCase(postLogout.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(postLogout.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", payload?.access);
      localStorage.setItem("refreshToken", payload?.refresh);
    });
    builder.addCase(postLogout.rejected, (state, action) => {
      state.loaders.common = false;
      state.isAuthenticated = false;
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      state.errors = action?.payload?.data;
    });
    //passwordReset
    builder.addCase(passwordReset.pending, (state) => {
      state.loaders.password = true;
      state.errorsPas.password = null;
    });
    builder.addCase(passwordReset.fulfilled, (state, { payload }) => {
      state.loaders.password = false;
      state.success.reset = true;
    });
    builder.addCase(passwordReset.rejected, (state, action) => {
      state.loaders.password = false;
      state.errorsPas.password = action?.payload?.data;
    });
    //passwordСhanging
    builder.addCase(passwordСhanging.pending, (state) => {
      state.loaders.password = true;
      state.errorsPas.password = null;
    });
    builder.addCase(passwordСhanging.fulfilled, (state, { payload }) => {
      state.success.changing = true;
      state.loaders.password = false;
    });
    builder.addCase(passwordСhanging.rejected, (state, action) => {
      state.loaders.password = false;
      state.errorsPas.password = action?.payload?.data;
    });
  },
});
export const {
  logout,
  clearSuccessReset,
  clearSuccessChanging,
  clearError,
  clearErrorPassword,
} = authSlice.actions;
export default authSlice.reducer;
