import { useState, useEffect } from "react";
import { Layout as AntdLayout, Col, Row, Space, ConfigProvider } from "antd";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { getError } from "../../store/fails/selectors";
import { setOpenSidebar } from "../../store/user";
import { getOpenSidebarSelector } from "../../store/user/selectors";
import { getErrorCorrections } from "../../store/corrections/selectors";
import { Footer } from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { Plug } from "../Plug";

export const Layout = ({ children, pageId, gap = 0 }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const openSidebar = useSelector(getOpenSidebarSelector);
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const errorCorrections = useSelector(getErrorCorrections);
  const isMobile = width < 640;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    if (openSidebar === null) {
      if (isMobile) {
        dispatch(setOpenSidebar(false));
      } else {
        dispatch(setOpenSidebar(true));
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch, isMobile]);

  return (
    <ConfigProvider>
      <AntdLayout
        style={{
          background: "transparent",
          display: "flex",
          gap: width < 640 ? "6px" : "20px",
        }}
      >
        <Sidebar pageId={pageId} openSidebar={openSidebar} width={width} />
        <AntdLayout style={{ background: "transparent" }}>
          {error?.type !== "client_error" && (
            <Header width={width} openSidebar={openSidebar} />
          )}
          <AntdLayout.Content
            style={{
              paddingTop: width > 640 ? 23 : 10,
              paddingRight: width > 640 ? 22 : 10,
              overflow: "hidden",
              backgroundColor: "#F9F9F9",
            }}
          >
            <Row style={{ padding: 0, margin: 0 }} justify="center">
              <Col
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Space
                  direction="vertical"
                  size={gap}
                  style={{ width: "100%" }}
                >
                  {error?.type === "client_error" ||
                  errorCorrections?.type === "client_error" ? (
                    <Plug />
                  ) : (
                    children
                  )}
                </Space>
              </Col>
            </Row>
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
      <Footer />
    </ConfigProvider>
  );
};
