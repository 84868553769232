import dayjs from "dayjs";
import { Radio, Divider, Descriptions } from "antd";

const dateFormat = "YYYY-MM-DD";

export const isInclude = (isInclude) => {
  return (
    <span
      style={{
        margin: "-20px 0",
        position: "absolute",

        color: isInclude ? "#219653" : "#EB5757",
      }}
    >
      {isInclude ? "Включить:" : "Исключить:"}
    </span>
  );
};

export const convertToLocalDate = (date) => {
  if (date) {
    return new Date(date).toLocaleString("ru-RU").replace(", ", " ");
  } else return null;
};

export const getDaysWord = (days) => {
  if (days === 1) {
    return "день";
  } else if (days > 1 && days < 5) {
    return "дня";
  } else {
    return "дней";
  }
};

export const resetDateFilterBefore = (value) => {
  const date = value?.split(" ")[0].split(".").reverse().join("-");
  // " " +
  // value?.split(" ")[1];
  return dayjs(date, dateFormat);
};

export const resetDateFilterAfter = (value) => {
  const date = value?.split(" ")[0]?.split(".")?.reverse().join("-");
  // " " +
  // value?.split(" ")[1];
  return dayjs(date, dateFormat);
};

export const convertDateToISO861 = (date) => {
  if (!date) return;
  const timezone = new Date().toString().split(" ")[5].slice(3);
  let actualDateArr = date.split(" ");
  let actualDate = actualDateArr[0].split(".").reverse().join("-");
  "T" + actualDateArr[1] + timezone;
  return actualDate;
};

export const convertDate = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return inputDate ? `${year}-${month}-${day} ${hours}:${minutes}` : "";
};

export const convertDateISO = (inputDate) => {
  let dateObj = new Date(inputDate);

  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let day = ("0" + dateObj.getDate()).slice(-2);
  let hours = ("0" + dateObj.getHours()).slice(-2);
  let minutes = ("0" + dateObj.getMinutes()).slice(-2);
  let seconds = ("0" + dateObj.getSeconds()).slice(-2);

  return inputDate
    ? `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
    : null;
};

export const convertDateCorrections = (inputDate) => {
  let dateObj = new Date(inputDate);

  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let day = ("0" + dateObj.getDate()).slice(-2);

  return inputDate ? `${year}-${month}-${day}` : null;
};

export const throwFormError = (form, error) => {
  if (error?.type === "validation_error") {
    const formFields = Object.keys(form.getFieldsValue());

    error.errors.forEach((err) => {
      if (formFields.includes(err.attr)) {
        form.setFields([
          {
            name: err.attr,
            errors: [err.detail],
          },
        ]);
      }
    });
  }
};

export const selectDropdown = (menu, include, setInclude) => {
  return (
    <>
      <Radio.Group
        onChange={() => {
          setInclude(!include);
        }}
        value={include}
      >
        <div
          style={{
            padding: "0 10px",
            fontSize: 20,
          }}
        >
          <Radio value={true}>Включить</Radio>
          <Radio value={false}>Исключить</Radio>
        </div>
      </Radio.Group>
      <Divider style={{ margin: "8px 0" }} />
      {menu}
    </>
  );
};

export const updateFilters = (
  name,
  isInclude,
  values,
  setFiltersArray,
  filtersArray
) => {
  if (values !== null && values !== undefined) {
    const filter = {
      key: isInclude ? `${name}` : `${"exc__" + name}`,
      value: values,
      text: values,
      title: name,
    };
    setFiltersArray([
      ...filtersArray.filter((value) => !value.key.includes(name)),
      filter,
    ]);
  } else {
    setFiltersArray(filtersArray.filter((value) => !value.key.includes(name)));
  }
};

export const updateFiltersArr = (
  name,
  isInclude,
  values,
  setFiltersArray,
  filtersArray
) => {
  if (values.length > 0) {
    const filter = {
      key: isInclude ? `${name}` : `${"exc__" + name}`,
      value: values.join(","),
      text: values.join(","),
      title: name,
    };
    setFiltersArray([
      ...filtersArray.filter((value) => !value.key.includes(name)),
      filter,
    ]);
  } else {
    setFiltersArray(filtersArray.filter((value) => !value.key.includes(name)));
  }
};

export function DescItem(label, content, key = label) {
  return (
    <Descriptions.Item key={key} label={label}>
      {content || "Нет данных"}
    </Descriptions.Item>
  );
}
