import { useState } from "react";
import styles from "./styles.module.css";
import User from "../../images/user.svg";
import ArrowBottom from "../../images/arrowBottom.svg";
import { Button, Typography } from "antd";
import imgBurger from "../../images/iconBurger.svg";
import { MenuProfile } from "../MenuProfile";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../store/user/selectors";
import { setOpenFails } from "../../store/fails";
import { setOpenCorrections } from "../../store/corrections";
import { getStudioMenuSelector } from "../../store/studios/selectors";
import { setOpenInfo } from "../../store/user";
import iconPlus from "../../images/iconPlus.svg";
import Help from "../../images/iconInfo.svg";
import { setOpenSidebar } from "../../store/user";

export const Header = ({ openSidebar, width }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const studioMenuTitle = useSelector(getStudioMenuSelector);

  return (
    <div className={styles.header}>
      <div className={styles.containerHeader}>
        <div className={styles.container}>
          {width > 640 && (
            <Button
              type="button"
              onClick={() => dispatch(setOpenSidebar(!openSidebar))}
              className={styles.buttonSidebar}
            >
              <img src={imgBurger} alt="burger" />
            </Button>
          )}
          <Typography.Text className={styles.text}>
            {location.pathname.includes("/complaints/") &&
              studioMenuTitle?.is_studio &&
              "Список жалоб"}
            {location.pathname.includes("/corrections/") && "Список коррекций"}
            {location.pathname.includes("/complaints_network/") &&
              !studioMenuTitle?.is_studio &&
              "Список жалоб сети"}
          </Typography.Text>
        </div>
        <Button
          type="button"
          className={styles.buttonHeader}
          onClick={() => setOpen(!open)}
        >
          {width > 640 && (
            <Typography.Text className={styles.textUser}>
              {userData?.external_data?.y_name || userData?.user?.username}
            </Typography.Text>
          )}
          <img src={User} alt="Пользователь" />
          <img src={ArrowBottom} alt="Стрелка" className={styles.arrow} />
        </Button>
        {open && <MenuProfile setOpen={setOpen} />}
      </div>
      <img
        src={Help}
        alt="info"
        className={styles.iconHelp}
        onClick={() => dispatch(setOpenInfo())}
      />
      {location.pathname.includes("/complaints/") &&
        studioMenuTitle?.is_studio && (
          <Button
            type="button"
            className={styles.button}
            onClick={() => dispatch(setOpenFails())}
          >
            <img src={iconPlus} alt="plus" />
            {width > 640 && (
              <span className={styles.button_text}>Новая жалоба</span>
            )}
          </Button>
        )}
      {location.pathname.includes("/corrections/") && (
        <Button
          type="button"
          className={styles.button}
          onClick={() => dispatch(setOpenCorrections())}
        >
          <img src={iconPlus} alt="plus" />
          {width > 640 && (
            <span className={styles.button_text}>Новая корреция</span>
          )}
        </Button>
      )}
    </div>
  );
};
