import { useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Complaints } from "../../pages/Complaints";
import { Corrections } from "../../pages/Corrections";
import { ComplaintsNetwork } from "../../pages/ComplaintsNetwork";
import { Login } from "../../pages/Login";
import { ResetPassword } from "../../pages/ResetPassword";
import { Layout } from "antd";
import { ChangingPassword } from "../../pages/ChangingPassword";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/user";
import { getUserData } from "../../store/user/selectors";
import { getStudioMenuSelector } from "../../store/studios/selectors";
import { setStudioMenu, getStudio } from "../../store/studios";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("accessToken");
  const location = useLocation();
  const userData = useSelector(getUserData);
  const studioMenuTitle = useSelector(getStudioMenuSelector);

  useEffect(() => {
    dispatch(getUser());
  }, [jwt]);

  useEffect(() => {
    if (!jwt && !["/reset", "/changing/"].includes(location.pathname)) {
      navigate("/login");
    }
  }, [jwt, userData]);

  return (
    <Layout
      style={{
        height: "100vh",
        gap: "10px",
        backgroundColor: "#F9F9F9",
        position: "relative",
      }}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/complaints/:id" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/complaints/:id" element={<Complaints />} />
        <Route path="/corrections/:id" element={<Corrections />} />
        <Route path="/changing" element={<ChangingPassword />} />
        <Route path="/complaints_network/:id" element={<ComplaintsNetwork />} />
      </Routes>
    </Layout>
  );
}

export default App;
